@import "_breakpoints.module"
@import "_colors.module"
@import "_elements.module"
@import "_typography.module"


$ModalZIndex: 1000
$ContentMaxWidth: 1282px

=flex-column
	display: flex
	flex-direction: column

=flex-column-center
	+flex-column()
	align-items: center
	justify-content: center

=flex-row
	display: flex
	flex-direction: row

=flex-row-center
	+flex-row()
	align-items: center
	justify-content: center

=flex-row-between
	display: flex
	flex-direction: row
	align-items: center
	justify-content: space-between

=center-element
	margin-left: auto
	margin-right: auto

=max-width-centered
	max-width: $ContentMaxWidth
	margin-left: auto
	margin-right: auto

=max-width-filled
	+max-width-centered
	width: stretch

// From helpers from other theme
// placeholder
=placeholder
	&::placeholder
		@content

// media query width
=r($width)
	@media (max-width: $width+ "px")
		@content

=rmin($width)
	@media (min-width: $width+ "px")
		@content

=text-overflow
	overflow: hidden
	text-overflow: ellipsis
	white-space: nowrap

// Common style patterns
// =card
//     +flex-column-center

=vertical-divider
	+flex-column-center
	border: 1px solid $black
	width: 0
	height: 100%
	

=noise-bg
	background-image: url('/background/noise.png')

=max-width-center
	max-width: $ContentMaxWidth
	width: 100%
	margin: 0 auto

// 
// Custom Scrollbar
// https://css-tricks.com/snippets/sass/custom-scrollbars-mixin/
// 
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%))
	overflow-y: auto
	// For Google Chrome
	&::-webkit-scrollbar
		width: $size

	&::-webkit-scrollbar-thumb
		border-radius: 8px
		background: $foreground-color

	&::-webkit-scrollbar-track
		border-radius: 8px
		background: $background-color
		height: 100%

	// For Internet Explorer
	&
		scrollbar-face-color: $foreground-color
		scrollbar-track-color: $background-color

@mixin notification($size: 14px, $top: auto, $right: auto, $bottom: auto, $left: auto)
	&::before
		content: ""
		position: absolute
		top: $top
		right: $right
		bottom: $bottom
		left: $left
		width: $size
		height: $size
		border-radius: 50%
		border: 2px solid $Neutral01
		background: $PrimaryRed

//  
// Functions
// 
@function spacing($units)
	@return (0.5 * $units) + rem
// @function spacing($units)
// 	@return (8 * $units) + px
