// Input
@import "styles/mixins.module.sass"

.field
	input
		width: 100%
		height: 100%
		&::placeholder
			opacity: 0.6

.inputAdornment
	display: flex
	align-items: center
	position: relative
	width: stretch

.error
	input
		border-color: $orange
		background: $orange30

.errorText
	margin-top: spacing(1)
	color: $orange