// Auth styles
@import "styles/mixins.module.sass"

.auth
	+flex-column
	background: linear-gradient(121.22deg, $white 30.67%, $pink 97.22%)
	width: 100%
	height: 100vh
	padding-top: spacing(8)
	+md
		padding-top: spacing(18)
	img
		+center-element
		margin-bottom: spacing(4)
	h1
		+center-element
		margin-bottom: spacing(7)

.authCard
	+flex-column-center
	+center-element
	border: 2px solid $black
	border-radius: 10px
	position: relative
	padding: spacing(2)
	padding-top: calc(spacing(2) + 64px) // Tab Bar Height
	max-width: 360px

.tabBar
	+flex-row-center
	justify-content: space-around
	position: absolute
	border-bottom: 1px solid $black
	top: 0
	left: 0
	right: 0

.tab
	+flex-column-center
	text-align: center
	height: 64px
	width: 100%
	&.selected
		font-weight: bold
		border-bottom: 3px solid $black

.inputWrapper
	margin-bottom: spacing(2)

.loadingContainer
	+flex-column-center
	width: stretch
	min-height: 128px

.footer
	margin-top: spacing(4)
	+flex-column-center
	text-align: center
	p:nth-child(2)
		margin-top: spacing(2)

.actionButton
	text-transform: uppercase